<template>
  <div>
    <row-button
      class="mb-2 mt-1"
      :to="{ name: 'PetNote' }"
      icon="plus"
      :padding-less="true"
    >
      Notitie toevoegen
    </row-button>

    <row-button
      class="mb-2 mt-1"
      :to="{ name: 'PetChecklist' }"
      icon="plus"
      :padding-less="true"
    >
      Checklist toevoegen
    </row-button>

    <timeline-item
      v-for="item in timeline"
      :key="`${item.type}-${item.id}`"
      :timeline-item="item"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TimelineItem from '../components/TimelineItem.vue';
import RowButton from '../components/RowButton.vue';

export default {
  components: {
    RowButton,
    TimelineItem,
  },

  created() {
    this.$store.dispatch('loadTimeline');
  },

  computed: {
    ...mapGetters([
      'timeline',
    ]),
  },
};
</script>
