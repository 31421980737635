<template>
  <div class="timeline-item">
    <div class="timeline-item__head">
      <div>
        <time class="d-block timeline-item__date" :datetime="timelineItem.date">
          {{ formatDate(timelineItem.date) }}
        </time>

        <div class="timeline-item__title" v-if="title">
          <img
            v-if="hasMilestone"
            class="mr-1"
            src="../assets/milestone-completed.svg"
            alt=""
            width="24"
          >
          {{ title }}
        </div>
      </div>

      <router-link
        class="timeline-item__head-edit pl-2"
        :to="editLink"
        v-if="editLink"
      >
        <img src="../assets/icon-pencil.svg" alt="" width="14">
      </router-link>
    </div>

    <template v-if="timelineItem.type === 'UserMediaItem'">
      <div class="timeline-item__image-wrapper">
        <img
          class="timeline-item__image"
          :src="timelineItem.url"
          :alt="
            timelineItem.milestone
              ? timelineItem.milestone.translation.title
              : timelineItem.title
          "
          width="640"
          height="640"
          loading="lazy"
        />

        <a
          :href="timelineItem.url"
          class="timeline-item__image-download btn btn--sm btn--primary"
          title="Download afbeelding"
          download
        >
          <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M13.5,0h5A1.5,1.5,0,0,1,20,1.5V12h5.48a1.25,1.25,0,0,1,.88,2.13l-9.5,9.51a1.21,1.21,0,0,1-1.71,0L5.63,14.13A1.25,1.25,0,0,1,6.51,12H12V1.5A1.5,1.5,0,0,1,13.5,0ZM32,23.5v7A1.5,1.5,0,0,1,30.5,32H1.5A1.5,1.5,0,0,1,0,30.5v-7A1.5,1.5,0,0,1,1.5,22h9.17l3.06,3.06a3.2,3.2,0,0,0,4.54,0L21.33,22H30.5A1.5,1.5,0,0,1,32,23.5ZM24.25,29A1.25,1.25,0,1,0,23,30.25,1.25,1.25,0,0,0,24.25,29Zm4,0A1.25,1.25,0,1,0,27,30.25,1.25,1.25,0,0,0,28.25,29Z" />
          </svg>
        </a>
      </div>

      <div class="timeline-item__title" v-if="timelineItem.title">
        {{ timelineItem.title }}
      </div>

      <div class="timeline-item__description" v-if="timelineItem.description">
        {{ timelineItem.description }}
      </div>
    </template>

    <template v-if="timelineItem.type === 'PetWeight'">
      <div class="timeline-item__weight" v-if="timelineItem.grams < 1000">
        {{ timelineItem.grams }} gram
      </div>
      <div class="timeline-item__weight" v-if="timelineItem.grams >= 1000">
        {{ (timelineItem.grams / 1000).toFixed(1) }} Kg
      </div>
    </template>

    <template v-if="timelineItem.type === 'PetHeight'">
      <div class="timeline-item__height" v-if="timelineItem.centimeters < 100">
        {{ timelineItem.centimeters }} Cm
      </div>
      <div class="timeline-item__height" v-if="timelineItem.centimeters >= 100">
        {{ (timelineItem.centimeters / 100).toFixed(1) }} M
      </div>
    </template>

    <template v-if="timelineItem.type === 'PetNote'">
      <div v-if="timelineItem.url" class="timeline-item__image-wrapper">
        <img
          class="timeline-item__image"
          :src="timelineItem.url"
          alt=""
          width="640"
          height="640"
          loading="lazy"
        />

        <a
          :href="timelineItem.url"
          class="timeline-item__image-download btn btn--sm btn--primary"
          title="Download afbeelding"
          download
        >
          <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M13.5,0h5A1.5,1.5,0,0,1,20,1.5V12h5.48a1.25,1.25,0,0,1,.88,2.13l-9.5,9.51a1.21,1.21,0,0,1-1.71,0L5.63,14.13A1.25,1.25,0,0,1,6.51,12H12V1.5A1.5,1.5,0,0,1,13.5,0ZM32,23.5v7A1.5,1.5,0,0,1,30.5,32H1.5A1.5,1.5,0,0,1,0,30.5v-7A1.5,1.5,0,0,1,1.5,22h9.17l3.06,3.06a3.2,3.2,0,0,0,4.54,0L21.33,22H30.5A1.5,1.5,0,0,1,32,23.5ZM24.25,29A1.25,1.25,0,1,0,23,30.25,1.25,1.25,0,0,0,24.25,29Zm4,0A1.25,1.25,0,1,0,27,30.25,1.25,1.25,0,0,0,28.25,29Z" />
          </svg>
        </a>
      </div>

      <div class="timeline-item__note">
        {{ timelineItem.note }}
      </div>
    </template>

    <template v-if="timelineItem.type === 'PetChecklist'">
      <div class="timeline-item__note">
        {{ timelineItem.title }}
      </div>
    </template>
  </div>
</template>

<script>
import { parseISO } from 'date-fns';
import dateFormat from '../helpers/dateFormat';

export default {
  props: {
    timelineItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    editLink() {
      if (this.timelineItem.type === 'UserMediaItem') {
        return {
          name: 'Photo',
          params: { id: this.timelineItem.id },
        };
      }

      if (this.timelineItem.type === 'PetWeight') {
        return {
          name: 'PetWeight',
          params: { id: this.timelineItem.id },
        };
      }

      if (this.timelineItem.type === 'PetHeight') {
        return {
          name: 'PetHeight',
          params: { id: this.timelineItem.id },
        };
      }

      if (this.timelineItem.type === 'PetNote') {
        return {
          name: 'PetNote',
          params: { id: this.timelineItem.id },
        };
      }

      if (this.timelineItem.type === 'PetChecklist') {
        return {
          name: 'PetChecklist',
          params: { id: this.timelineItem.id },
        };
      }

      return null;
    },

    title() {
      if (this.timelineItem.type === 'UserMediaItem') {
        if (this.timelineItem.milestone) {
          return this.timelineItem.milestone.translation.title;
        }

        return null;
      }

      if (this.timelineItem.type === 'PetWeight') {
        return 'Gewicht toegevoegd';
      }

      if (this.timelineItem.type === 'PetHeight') {
        return 'Grootte toegevoegd';
      }

      if (this.timelineItem.type === 'PetNote') {
        if (this.timelineItem.is_milestone) {
          return 'Mijlpaal toegevoegd';
        }

        return 'Notitie toegevoegd';
      }

      if (this.timelineItem.type === 'PetChecklist') {
        return 'Checklist toegevoegd';
      }

      return null;
    },

    hasMilestone() {
      return !!this.timelineItem.milestone || this.timelineItem.is_milestone;
    },
  },

  methods: {
    formatDate(date) {
      return dateFormat(parseISO(date), 'PPP');
    },
  },
};
</script>

<style scoped>
.timeline-item {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-top: 1px solid #f3f3f4;
}

.timeline-item__date {
  color: #c5c6c6;
  margin-bottom: .25rem;
}

.timeline-item__image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: .625rem;
  margin-bottom: 1rem;
}

.timeline-item__image-wrapper {
  position: relative;
}

.timeline-item__image-download {
  width: 2rem;
  right: 1rem;
  bottom: 1rem;
  height: 2rem;
  padding: 0;
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
}

.timeline-item__image-download > svg {
  width: 1rem;
  height: 1rem;
}

.timeline-item__head {
  margin-bottom: 1rem;
  display: flex;
}

.timeline-item__head-edit {
  flex: 0 0 auto;
  margin-left: auto;
}

.timeline-item__title {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple);
  display: flex;
  align-items: center;
}

.timeline-item__weight,
.timeline-item__height {
  margin-top: -.625rem;
}

.timeline-item__description {
  margin-top: .375rem;
}
</style>
