<template>
  <app-layout classes="pet">
    <div class="pet__head mt-2 mt-md-3 p-2 p-md-3" v-if="user && user.active_pet">
      <div
        v-if="user.active_pet.avatar && user.active_pet.avatar.indexOf('avatar-pet') < 0"
        class="pet__head-avatar"
      >
        <img
          :src="user.active_pet.avatar"
          :alt="`${user.active_pet.name} avatar`"
          width="128"
          height="128"
        />

        <img
          v-if="theme.value === 'christmas'"
          src="/img/themes/christmas/twig.svg"
          alt="Kersttakje"
          class="pet-head__avatar-decoration"
        >
      </div>

      <div class="pet__head-content">
        <h1 class="pet__head-name">
          {{ user.active_pet.name }}
        </h1>
        <div>
          {{ petBirthdate }}<br>
          {{ user.active_pet.race }}
        </div>

        <router-link
          class="pet-head__link"
          :to="{ name: 'PetProfile' }"
        >
          <img src="../assets/icon-pencil.svg" alt="" aria-hidden="true" />
          Wijzig profiel
        </router-link>
      </div>
    </div>

    <tabs
      :active-tab="petTab"
      :tabs="[
        { identifier: 'milestones', title: 'Mijlpalen' },
        { identifier: 'timeline', title: 'Tijdlijn' },
      ]"
      @change-tab="changeTab"
    />

    <div class="p-2 p-md-3">
      <div v-if="petTab === 'milestones'">
        <ul>
          <li class="mb-2" v-for="milestone in milestones" :key="milestone.id">
            <search-result
              v-if="milestone.primary_article"
              :title="milestone.translation.title"
              :to="{ name: 'Snack', params: { slug: milestone.primary_article.translation.slug } }"
              :milestone-completed="milestone.completed"
              :milestone-skipped="milestone.skipped || milestone.has_passed"
            />

            <search-result
              v-else
              :title="milestone.translation.title"
              :to="{ name: 'Milestone', params: { slug: milestone.translation.slug } }"
              :milestone-completed="milestone.completed"
              :milestone-skipped="milestone.skipped || milestone.has_passed"
            />
          </li>
        </ul>
      </div>

      <div v-if="petTab === 'timeline'">
        <pet-timeline />
      </div>
    </div>
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO } from 'date-fns';
import AppLayout from './AppLayout.vue';
import SearchResult from '../components/SearchResult.vue';
import Tabs from '../components/Tabs.vue';
import dateFormat from '../helpers/dateFormat';
import PetTimeline from './PetTimeline.vue';

export default {
  inject: ['theme'],

  components: {
    PetTimeline,
    Tabs,
    SearchResult,
    AppLayout,
  },

  created() {
    this.$store.dispatch('loadMilestones');
    this.$store.dispatch('loadMediaItems');
  },

  methods: {
    changeTab(key) {
      this.$store.commit('setPetTab', key);
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'milestones',
      'mediaItems',
      'petTab',
    ]),

    petBirthdate() {
      if (!this.user || !this.user.active_pet) {
        return null;
      }

      const { birthdate } = this.user.active_pet;

      if (!birthdate) {
        return '';
      }

      return dateFormat(parseISO(birthdate), 'd MMMM yyyy');
    },
  },
};
</script>

<style>
.app-layout.pet {
  background-color: #fff;
}

.pet__head {
  display: flex;
  margin-bottom: 1rem;
}

.pet__head-avatar {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  flex: 0 0 6.5rem;
  margin-right: 1rem;
  position: relative;
}

.pet__head-avatar > img:not(.pet-head__avatar-decoration) {
  width: 6.5rem;
  height: 6.5rem;
  object-fit: cover;
  border-radius: 50%;
}

.pet-head__avatar-decoration {
  left: .5rem;
  width: 4rem;
  bottom: -1rem;
  position: absolute;
}

.pet__head-name {
  font-size: 1.25rem;
  margin-bottom: .25rem;
}

.pet-head__link {
  font-weight: 500;
  color: var(--purple);
  text-decoration: none;
  margin-top: .875rem;
  display: inline-block;
}

.pet-head__link img {
  margin-right: .25rem;
}

.pet-photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.pet-photo-grid__photo {
  display: block;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.pet-photo-grid__photo img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: .3125rem;
}

@media (min-width: 540px) {
  .pet-photo-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 640px) {
  .tabs {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
</style>
