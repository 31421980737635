<template>
  <component
    :is="componentType"
    :class="{
      'search-result': true,
      'btn-reset': true,
      'search-result--skipped': milestoneSkipped,
      'search-result--completed': milestoneCompleted,
    }"
    :to="to"
  >
    <div
      :class="`search-result__icon ${icon === 'logout' ? 'is-logout' : ''}`"
      aria-hidden="true"
      v-if="icon"
    >
      <Icon :identifier="icon" />
    </div>

    <div class="search-result__milestone" v-if="milestoneCompleted !== null">
      <img
        v-if="milestoneCompleted"
        src="../assets/milestone-completed.svg"
        :alt="`${milestoneCompleted ? 'Mijlpaal afgerond' : 'Mijlpaal niet afgerond'} icoon`"
      >
      <img
        v-if="!milestoneCompleted"
        src="../assets/milestone-incomplete.svg"
        :alt="`${milestoneCompleted ? 'Mijlpaal afgerond' : 'Mijlpaal niet afgerond'} icoon`"
      >
    </div>

    <div>
      <div class="search-result__title">{{ title }}</div>
      <div class="search-result__label" v-if="label">{{ label }}</div>
    </div>

    <img
      class="search-result__arrow"
      src="../assets/arrow-right-grey.svg"
      alt=""
      aria-hidden="true"
    />
  </component>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },

  props: {
    componentType: {
      type: String,
      default: 'router-link',
    },

    title: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    icon: {
      type: String,
    },

    to: {
      type: [Object, null],
      default: null,
    },

    milestoneCompleted: {
      type: [Boolean, null],
      default: null,
    },

    milestoneSkipped: {
      type: [Boolean, null],
      default: false,
    },
  },
};
</script>

<style>
.search-result {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--purple);
  width: 100%;
}

.search-result__icon {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  background-color: #f2eaf0;
  border-radius: 50%;
  margin-right: .875rem;
  position: relative;
}

.search-result__icon svg {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  fill: var(--purple);
  transform: translate(-50%, -50%);
}

.search-result__icon.is-logout {
  background-color: #ffd7d7;
}

.search-result__icon.is-logout svg {
  fill: #e3000f;
}

.search-result__arrow {
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.search-result__title {
  font-weight: 700;
}

.search-result__label {
  font-weight: 400;
}

.search-result__milestone {
  margin-right: 1rem;
}

.search-result__milestone img {
  width: 2.25rem;
}

.search-result--skipped:not(.search-result--completed) {
  color: #999;
}

.search-result--skipped:not(.search-result--completed) .search-result__title {
  font-weight: 400;
}
</style>
