<template>
  <div class="tabs">
    <button
      v-for="tab in tabs"
      :key="tab.identifier"
      :class="['tabs__tab', 'btn', activeTab === tab.identifier ? 'btn--primary' : '']"
      type="button"
      @click="() => changeTab(tab.identifier)"
    >
      {{ tab.title }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    changeTab(identifier) {
      this.$emit('changeTab', identifier);
    },
  },
};
</script>

<style scoped>
.tabs {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
}

.tabs__tab {
  flex-grow: 1;
  margin: 0;
}

.tabs__tab:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tabs__tab:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tabs__tab:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tabs__tab:not(:last-child) {
  border-right: 0;
}
</style>
